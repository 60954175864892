<template>
  <div>
    <v-card class="pa-0" flat>
      <v-card-text class="pt-3 lightGrey rounded-0" style="position: sticky; z-index: 100; min-width: 100%; top: 47px; margin-bottom: 47px; border-bottom: 1px solid var(--light-blue-grey) !important">
        <v-row class="text-right" justify="end" no-gutters>
          <v-btn v-if="$helpers.hasPermission(['delete_member'])" @click="dialogDelete=true" outlined>Eliminar grupo</v-btn>
          <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="20" transition="slide-y-transition" absolute z-index="1000" min-width="145">
            <template v-slot:activator="{ on, attrs }">
              <v-btn class="ml-2" v-bind="attrs" v-on="on" color="primary">
                <v-icon left>mdi-pencil</v-icon>
                Editar grupo
                <v-icon size="18" right>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list class="pa-0 bgSearch">
              <v-list-item class="px-3" @click="goTo(0)" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2">Información básica</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" @click="goTo(1)" active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2">Permisos</v-list-item-title>
              </v-list-item>
              <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_member'])" @click="goTo(2)"  active-class="transparent" :ripple="false">
                <v-list-item-title class="body-2">Miembros</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-row>
        <v-col cols="12" class="pa-0">
          <v-toolbar color="transparent" flat dense>
            <v-toolbar-title class="pl-1 subtitle-1 font-weight-bold secondary--text"><v-icon left size="20" color="secondary">mdi-account-group</v-icon>Grupo
            </v-toolbar-title>
          </v-toolbar>
          <!-- basic inf-->
          <v-col class="px-1 pt-0 pb-1">
            <span class="font-weight-bold secondary--text">Nombre: </span>
            <v-avatar v-if="loadingDetail" tile class="placeholder rounded-pill" height="15" width="280" />
            <template v-else>
            <span v-if="name" class="fontDraw--text">{{name}}</span>
            <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
          </v-col>
          <v-col class="px-1 py-0">
            <span class="font-weight-bold secondary--text">Descripción: </span>
            <v-avatar v-if="loadingDetail" tile class="placeholder rounded-pill" height="15" width="500" />
            <template v-else>
            <span v-if="description" class="fontDraw--text">{{description}}</span>
            <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
            </template>
          </v-col>
          <!-- end basic inf -->
        </v-col>
      </v-card-text>
      <v-card-text class="pa-0 overflow-y-auto">
        <skeleton-groups v-if="loadingDetail" class="mt-5" type="panel" />
        <v-col class="pb-6 px-5" v-else>
          <v-toolbar class="mb-n2" color="transparent" flat dense>
            <v-toolbar-title class="subtitle-1 font-weight-bold secondary--text"><v-icon left size="20" color="secondary">mdi-cog</v-icon>Permisos</v-toolbar-title>
          </v-toolbar>
          <v-col class="py-5 pl-1" v-if="!(list.filter((elm) => elm.children.some((item) => item.active))).length">
            <span class="d-block body-1 fontDraw--text">No existen permisos</span>
          </v-col>
          <template v-else>
            <div v-for="(item, i) in list" :key="i">
              <span class="my-3 d-block font-weight-bold fontBody--text" v-if="((item.children || []).filter((item) => item.active) || []).length">{{item.category}}</span>
              <v-row class="ma-0">
                <v-col cols="6" class="pa-0" v-for="(c, index) in (item.children || []).filter((item) => item.active)" :key="index">
                  <span class="d-inline-block text-truncate" style="max-width: 230px;"><v-icon class="mr-1" x-small color="primary">mdi-circle-medium</v-icon>{{c.name}}</span>
                  <!-- <div class="ml-6" v-if="c.name.search(/documentos/) !== -1 && item.category === 'Documentos'">
                    <span class="d-block" v-for="(doc, i) in currentDocumentListEnabled" :key="i">
                      <span class="pa-0 body-1 d-inline-block text-truncate" style="max-width: 90%;"  v-if="doc.permissions.includes(c.id)">{{doc.name}}</span>
                    </span>
                  </div> -->
                </v-col>
              </v-row>
            </div>
          </template>
        </v-col>
        <template v-if="((currentMembersList || []).filter((item) => item.active) || []).length && $helpers.hasPermission(['view_member'])">
          <v-divider />
          <skeleton-groups class="mt-4" v-if="loadingMembers" type="panel" />
          <v-col class="pt-1 px-5">
            <v-toolbar class="mt-2" color="transparent" flat dense>
              <v-toolbar-title class="subtitle-1 font-weight-bold secondary--text"><v-icon left size="20" color="secondary">mdi-account</v-icon>Miembros</v-toolbar-title>
            </v-toolbar>
            <v-row no-gutters>
              <v-col cols="6" class="pa-0" v-for="(member, index) in ((currentMembersList || []).filter((item) => item.active) || [])" :key="index">
                <span class="d-inline-block text-truncate" style="max-width: 230px;">
                  <v-icon class="mr-1" x-small color="primary">mdi-circle-medium</v-icon>
                  <span v-if="[member.first_name, member.last_name].includes('') || [member.first_name, member.last_name].includes(' ')">
                    <span v-if="member.email">{{member.email}}</span>
                    <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                  </span>
                  <span class="text-capitalize" v-else> {{member.first_name | lower}} {{member.last_name | lower}}</span>
                </span>
              </v-col>
            </v-row>
          </v-col>
        </template>
      </v-card-text>
    </v-card>
    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">Al eliminar el grupo, los usuarios asociados quedarán con todos los permisos activos</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroyGroup" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->
  </div>
</template>
<script>
import permissionList from '@/collections/permissionsList'
import { groupBy } from 'lodash'
import { mapState } from 'vuex'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import DocumentTypes from '@/collections/documentTypes'
import SkeletonGroups from '@/modules/account/views/groups/components/SkeletonGroups'

  export default {
    props: {
      id: {
        type: String,
        default: ''
      },
      active: {
        type: Boolean,
        default: false
      }
    },
    components: {
      SkeletonGroups
    },
    mixins: [
    GenericViewMixin
    ],
    data: () => ({
      name: '',
      description: '',
      confirm: '',
      dialogDelete: false,
      permissionList,
      list: [],
      loading: false,
      loadingMembers: false,
      loadingDetail: false,
      documentTypes: DocumentTypes,
      currentMembersList: [],
      permissionsList: [],
      currentDocumentListEnabled: []
    }),
    computed: {
      ...mapState({
        membersList: state => state.accounts.membersList,
        groupsList: state => state.accounts.groupsList
      }),
      currentParams () {
        let params = JSON.parse(JSON.stringify(this.$route.params))
        delete params.id
        return params
      }
    },
    watch: {
      active (val) {
        if (val) {
          this.getData()
        }
      }
    },
    methods: {
      getData () {
        this.permissionsList = JSON.parse(JSON.stringify(permissionList))
        if (this.$helpers.hasPermission(['view_member'])) {
          this.loadingMembers = true
          this.$store.dispatch('accounts/LIST', {
            resource: 'members',
            query: {
              ...this.$route.query,
              page_size: 100
            }
          })
          .then((response) => {
            this.currentMembersList = [...response.data.results]
            this.currentMembersList.forEach((item) => {
              item.active = item.groups.includes(this.id)
            })
          })
          .finally(() => {
            this.loadingMembers = false
          })
        }
        this.loadingDetail = true
        this.$store.dispatch('accounts/RETRIEVE', {
          resource: 'groups',
          id: this.id,
          loader: this.$route.name === 'AccountGroupsUpdate'
        })
        .then((response) => {
          const { name, description, permissions } = response.data
          this.name = name
          this.description = description
          this.list = [...this.permissionsList]
          this.list.forEach((item) => {
            if (permissions.includes(item.id)) {
              item.active = true
            }
          })
          this.list = [...this.groupBy(this.list)]

          /** set documentType */
          let documentTypePermissions = Object.entries(groupBy(response.data.document_type_permissions, 'document_type')).map(([key, value]) => {
            return {
              id: key,
              permissions: value.map(({permission}) => permission)
            }
          })

          this.currentDocumentListEnabled = this.documentTypes?.filter((d) => {
            const founded = documentTypePermissions.find((item) => item.id === d.id)
            if (founded !== undefined) {
              d.permissions = founded.permissions
              return true
            }
            return false
          })
          this.list.forEach((item) => {
            if (item.category === 'Documentos') {
              item.children.forEach((elm) => {
                if (['add_document', 'view_document'].includes(elm.id)) {
                  elm.active = this.currentDocumentListEnabled.some(({permissions}) => permissions.includes(elm.id))
                }
              })
            }
          })
          /** end set documentType */
        })
        .finally(() => {
          this.loadingDetail = false
        })
      },
      groupBy (list = []) {
        return Object.entries(groupBy(list, 'category')).map(([key, value]) => {
          return {
            category: key,
            children: value
          }
        })
      },
        goTo (step = 0) {
          this.$emit('close')
          setTimeout(() => {
            this.$router.push({ name: 'AccountGroupsUpdate', params: { id: this.id, ...this.currentParams}, query: {step: step} })
          }, 100)
      },
      destroyGroup () {
        this.$store.dispatch('accounts/DESTROY', {
          resource: 'groups',
          id: this.id
        })
        .then(() => {
          this.panel = false
          this.$emit('updateList')
          this.$dialog.message.info('El grupo se ha sido eliminado con éxito')
          this.$router.replace({ name: 'AccountGroups', params: this.currentParams}).catch(() => {})
        })
        .catch((error) => {
          this.$dialog.message.error(this.parseErrors(error.response.data))
        })
        .finally(() => {
          this.dialogDelete = false
          this.confirm = ''
        })
      }
    }
  }
  </script>