<template>
  <v-row class="pb-5">
    <v-col cols="12" class="py-0">
      <v-toolbar color="transparent" flat height="55">
        <v-spacer />
        <v-toolbar-items>
          <v-row align="center" justify="end" no-gutters>
            <v-btn v-if="$helpers.hasPermission(['add_group'])" :to="{ name: 'AccountGroupsCreate', params: $route.params }" color="primary"><v-icon left>mdi-account-multiple-plus</v-icon>Crear grupo</v-btn>
          </v-row>
        </v-toolbar-items>
      </v-toolbar>
    </v-col>
    <template v-if="!$store.state.accounts.LoaderMe">
      <PermissionDenied v-if="!$helpers.hasSomePermission(['view_group'])" :isDetail="true" />
      <template v-else>
        <v-col cols="12" v-if="loading">
          <skeleton-list />
        </v-col>
        <template v-else>
          <v-col cols="12" v-if="currentList.length">
            <v-row>
              <v-col v-for="(group, i) in currentList" :key="i" cols="4" :md="$vuetify.breakpoint.width < 1200 ? 3 : 2">
                <v-hover v-slot="{ hover }">
                  <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" flat @click="current = group.id, panel=true">
                    <v-row no-gutters>
                        <v-icon class="mb-1" color="primary">mdi-account-group</v-icon>
                        <v-spacer />
                        <v-menu v-model="menu[i]" close-on-click close-on-content-click offset-y left :nudge-bottom="0" :nudge-left="25" transition="slide-y-transition">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn class="mt-n1 mr-n2" text v-bind="attrs" v-on="on">
                              <v-icon color="secondary" size="24">mdi-dots-vertical</v-icon>
                            </v-btn>
                            </template>
                          <v-list class="pa-0">
                            <v-list-item class="px-3 pt-1" @click="current = group.id, panel=true" :ripple="false">
                              <v-list-item-title class="body-2">Ver detalle</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="$router.replace({ name: 'AccountGroupsUpdate', params: { ...currentParams, id: group.id }, query: {step: 0} })" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar información básica</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" @click="$router.replace({ name: 'AccountGroupsUpdate', params: { ...currentParams, id: group.id }, query: {step: 1} })" active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar permisos</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" v-if="$helpers.hasPermission(['view_member'])" @click="$router.replace({ name: 'AccountGroupsUpdate', params: { ...currentParams, id: group.id }, query: {step: 2} })"  active-class="transparent" :ripple="false">
                              <v-list-item-title class="body-2">Editar miembros</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="px-3" v-if="$helpers.hasPermission(['delete_member'])" :ripple="false" @click="dialogDelete = true, current = group.id">
                              <v-list-item-title class="body-2">Eliminar grupo</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-row>
                      <span class="d-block">
                        <v-sheet class="transparent" height="25">
                          <span class="secondary--text font-weight-medium body-1 d-inline-block text-truncate" style="max-width: 99%;">{{group.name}}</span>
                        </v-sheet>
                      </span>
                      <template v-if="$helpers.hasPermission(['view_member'])">
                        <span class="mt-n2 d-block body-2 fontDraw--text d-inline-block d-inline-block text-truncate" style="max-width: 99%; line-height: 22px;"> {{ group.countMembers }} {{ `miembro${group.countMembers !== 1 ? 's' : ''}`}}</span>
                      </template>
                  </v-card>
                </v-hover>
              </v-col>
            </v-row>
            <v-col class="py-0">
              <VMainPagination :count="count" />
            </v-col>
          </v-col>
          <v-col v-else>
            <v-empty-state type="groups" id="grupos" :isFree="true" title="No existen grupos para mostrar" customClass="mt-0">
              <!-- <template v-slot:actions>
                <template v-if="!routeList.includes($route.name)">
                  <v-btn @click="$emit('activeBtnFilter')" color="primary" :ripple="false" :width="153"><v-icon class="mr-2">mdi-filter-plus</v-icon>Filtrar</v-btn>
                  <v-btn class="ml-2" @click="setSort('-date')" outlined :ripple="false" width="153"><v-icon class="mr-2">mdi-text-search-variant</v-icon>Últimos registros</v-btn>
                </template>
              </template> -->
            </v-empty-state>
          </v-col>
        </template>
      </template>
    </template>
    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="600" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Eliminar grupo</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <span class="d-block">Al eliminar el grupo, los usuarios asociados quedarán con todos los permisos activos</span>
          <span class="d-block mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
          <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroyGroup" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->
    <VPanel :active="panel" title="Información del grupo" @close="panel=false">
      <GroupsRetrieve :active="panel" :id="current" @close="panel=false" @updateList="handleUpdate"  />
    </VPanel>
  </v-row>
</template>
<script>
import { mapState } from 'vuex'
import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
import VEmptyState from '@/components/commons/VEmptyState'
import VPanel from '@/components/commons/VPanel'
import GroupsRetrieve from '@/modules/account/views/groups/GroupsRetrieve'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import ListViewMixin from '@/mixins/ListViewMixin'
import VMainPagination from '@/components/commons/VMainPagination'
import PermissionDenied from '@/modules/http/views/PermissionDenied'

export default {
  components: { VMainPagination, SkeletonList, VEmptyState, PermissionDenied, VPanel, GroupsRetrieve },
  mixins: [
    GenericViewMixin, ListViewMixin
  ],
  data: () => ({
    menu: [false],
    panel: false,
    confirm: '',
    current: null,
    dialogDelete: false,
    loading: false,
    list: [],
    env: process.env?.VUE_APP_MODE ?? 'ax'
  }),
  computed: {
    ...mapState({
      membersList: state => state.accounts.membersList,
      groupsList: state => state.accounts.groupsList,
      count: state => state.accounts.groupsCount
    }),
    currentList () {
      return this.$helpers.hasPermission(['view_member']) ? this.list : this.groupsList
    }
    // currentParams () {
    //   let params = JSON.parse(JSON.stringify(this.$route.params))
    //   delete params.id
    //   return params
    // }
  },
  created () {
    this.getList()
  },
  methods: {
    handleUpdate () {
      this.panel = false
      this.getList()
    },
    async getList () {
      const request = this.$helpers.hasPermission(['view_member', 'view_group']) ? ['groups', 'members'] : ['groups']
      this.loading = true
      await Promise.all(request.map(async (element) => {
        await this.$store.dispatch('accounts/LIST', {
          resource: element,
          query: element !== 'groups' ? { page_size: 100 } : {}
        })
        this.list = this.groupsList.map(item => {
          return {
            ...item,
            countMembers: this.membersList?.filter(({groups}) => groups?.includes(item.id))?.length ?? 0
          }
        })
      }))
      this.loading = false
    },
    destroyGroup () {
      this.$store.dispatch('accounts/DESTROY', {
        resource: 'groups',
        id: this.current
      })
      .then((response) => {
        this.$dialog.message.info('El grupo se ha sido eliminado con éxito')
        this.$router.replace({ name: 'AccountGroups', params: this.$route.params })
        this.getList()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.dialogDelete = false
      })
    }
  }
}
</script>